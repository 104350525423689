
.bag-content {
    height: 100%;
    display: flex;
    flex-direction: column;

    .el-scrollbar {
        margin: 20px;
        height: calc(100% - 40px);
    }

    ::v-deep .el-scrollbar__wrap {
        height: 100%;
        overflow-x: hidden;

        .el-scrollbar__view {
            height: 100%;
        }
    }

    ::v-deep .el-input-number .el-input__inner {
        text-align: left;
    }

    .top-form {
        margin-top: 39px;

        .el-form {
            margin-top: 30px;

            .el-input {
                width: 380px;
            }
        }
    }

    .act-box {
        width: 124px;
        height: 40px;
        background: #f0f3fc;
        border: 1px solid #dadce7;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .table-script {
        position: relative;

        .left-single {
            position: absolute;
            left: -40px;
            top: 12px;
            width: 20px;
            height: 20px;
            border-left: 1px solid #dadce7;
            border-bottom: 1px solid #dadce7;
        }
    }

    .inner-unit {
        margin: 0 10px;
    }

    .title-item {
        font-size: 16px;
        font-weight: 500;
        color: #060111;
        position: relative;
        padding-left: 16px;

        &:before {
            content: "";
            position: absolute;
            width: 6px;
            height: 12px;
            background: #2821fc;
            border-radius: 3px;
            left: 0;
            top: 5px;
        }
    }

    .script-content {
        margin-top: 74px;

        .el-table {
            margin-top: 20px;
            border: 3px solid #f7f8fc;

            ::v-deep th {
                &:nth-of-type(2) {
                    & > .cell {
                        padding-left: 40px;
                    }
                }
            }

            ::v-deep td {
                height: 60px;

                &:nth-of-type(2) {
                    & > .cell {
                        padding-left: 40px;
                    }
                }
            }
        }

        .footer-button {
            margin-top: 89px;
            text-align: center;
        }
    }
}
